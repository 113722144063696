import { CookieRegistry } from '@/constants/cookieRegistry'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { trackEvent } from '@/lib/ga/utils/tracking'
import { deleteCookie } from '@/utils/cookies/deleteCookie'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'

/**
 * When a user successfully posts or edits a listing on PAF,
 * BOX creates a postAd or editAd cookie and then redirects to the sVIP
 * This function checks for those cookies and fires the appropriate event
 * @param categoryId - The category id of the VIP listing
 */
export const handlePostAdEvents = (categoryId: number) => {
  const postAd = getCookieByKey(document.cookie, CookieRegistry.POST_AD)
  const editAd = getCookieByKey(document.cookie, CookieRegistry.EDIT_AD)

  if (postAd) {
    const isPaid = postAd === 'paid'
    trackEvent({
      action: isPaid ? GA_EVENT.PostAdPaidSuccess : GA_EVENT.PostAdFreeSuccess,
      label: `catId=${categoryId}`,
    })
    deleteCookie(CookieRegistry.POST_AD, '')
  }

  if (editAd) {
    const isPaid = editAd === 'paid'
    trackEvent({
      action: isPaid ? GA_EVENT.EditAdPaidSuccess : GA_EVENT.EditAdFreeSuccess,
      label: `catId=${categoryId}`,
    })
    deleteCookie(CookieRegistry.EDIT_AD, '')
  }
}
